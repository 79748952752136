.view.auth {
  //padding: 0 15px;
  background: $white;

  .auth-row {
    display: flex;

    .auth-left-panel {
      height: 100vh;
      padding: 0 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      @media (min-width: $tablet-portrait) {
        max-width: 47%;
        padding: 0 60px 0 40px;
      }
    }

    .auth-right-panel {
      background: $ac-grey;
      color: $white;
      height: 100vh;
      overflow: auto;
      margin: auto 0;
      padding: 0 60px;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      text-align: center;
      display: none;
      position: fixed;
      right: 0;

      @media (min-width: $tablet-portrait) {
        display: flex;
        max-width: 53%;
      }

      * {
        color: inherit;
      }

      img {
        margin-bottom: 150px;
        border-radius: $input-border-radius;
        max-height: 300px;
      }

      h2 {
        font-weight: 400;
        margin-bottom: 40px;
      }

      p {
        margin: 0 30px;
      }
    }

    .auth-panel-inner {
      max-height: 100%;
      padding-top: 110px;

      @media (min-width: $tablet-portrait) {
        max-width: 550px;
      }

      .auth-title-bar {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      .btn-primary {
        margin: 35px 0;
      }

      .form-error {
        margin-bottom: -17px;
      }

      .redirect-actions {
        text-align: center;

        * {
          font-size: 14px;
          line-height: 17px;
        }

        .btn {
          margin-left: 5px;
        }
      }
    }
  }

  &.onboarding {
    &.details {
      .auth-title-bar {
        .pl-back {
          transition: opacity 0.5s;
        }

        &.step-0 {
          .pl-back {
            opacity: 0;
          }
        }

        &.step-1 {
          .pl-back {
            opacity: 1;
          }
        }
      }

      .auth-panel-inner {
        .details-container {
          overflow: hidden;
          margin: 0 -15px;
          width: calc(100vw - 30px);

          @media(min-width: $tablet-portrait) {
            width: unset;
          }

          .details-row {
            width: 200%;
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            transition: all 0.5s;

            &.step-0 {
              margin-left: 0;
            }

            &.step-1 {
              margin-left: -100%;
            }

            > * {
              width: 100%;
              margin: 0 15px;
            }
          }
        }
      }
    }

    .auth-left-panel {
      @media (min-width: $tablet-portrait) {
        padding-left: 80px;
      }
    }

    .upload-logo {
      width: 250px;
      height: 250px;
      border-radius: $input-border-radius;
      cursor: pointer;
      background: $input-background-color;
      margin: 10px 0;
      padding: 10px;
      position: relative;
      transition: $input-transition;

      &:empty:after {
        display: block;
        content: url("https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-logo-icon.svg");
        position: absolute;
        left: 100px;
        top: 100px;
      }

      &.error {
        box-shadow: $input-error-bs;
      }

      img {
        max-width: 100%;
        display: block;
        border-radius: $input-border-radius;
      }
    }
  }
}

.pl-card {
  padding: $card-padding;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  background: $card-background;
}

.BHG-pill-container {
  .BHG-pill {
    background: $white;
    color: $brand-primary;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid $brand-primary;
    border-radius: $input-border-radius;
    margin: 0 12.5px 12.5px 0;

    &.active {
      background: $brand-primary;
      color: $white;
    }
  }
}

.on-boarding-progress {
  margin: -40px 0 35px;
  display: block;

  .steps {
    display: flex;

    .step {
      display: block;
      margin-right: 10px;
      height: 5px;
      width: 100%;
      max-width: 100px;
      background: $input-background-color;
      transition: all 0.5s;

      &.active {
        background: $pl-blue;
      }
    }
  }
}

.onboarding-services-selection {
  .top {
    opacity: 0;
    margin-top: -130px;
    transition: all 0.5s;

    &.active {
      margin-top: 0;
      opacity: 1;
    }

    .on-boarding-progress {
      margin: 20px 0 35px;
    }
  }

  .services-container {
    overflow: hidden;
    margin: 0 -15px;
    width: calc(100vw - 30px);

    @media(min-width: $tablet-portrait) {
      width: unset;
    }

    .services-row {
      width: 300%;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      transition: all 0.5s;

      .onboarding-intro {
        max-height: 40px;
        transition: max-height 0.05s;
      }

      &.step-0 {
        margin-left: 0;

        .onboarding-intro {
          max-height: 100%;
        }
      }

      &.step-1 {
        margin-left: -100%;
      }

      &.step-2 {
        margin-left: -200%;
      }

      &.step-3 {
        margin-left: -300%;
      }

      &.step-4 {
        margin-left: -400%;
      }

      &.step-5 {
        margin-left: -500%;
      }

      > * {
        width: 100%;
        margin: 0 15px;
      }
    }
  }
}

.onboarding-intro {
  text-align: center;
  margin: 0 0 0 -15px;

  .mascot {
    margin-bottom: 15px;
    width: 266px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 85px;
  }

  .btn.btn-primary {
    margin: 0 0 20px !important;
  }
}

.onboarding-service {
  height: 100%;
  display: flex;
  flex-direction: column;

  .service {
    height: 100%;
    display: flex;
    flex-direction: column;

    .select-all {
      display: flex;
      align-items: center;
      margin: 10px 0 20px;

      .BHG-pill-container {
        margin-left: 30px;

        .BHG-pill {
          margin: 0;
        }
      }
    }

    .btn-primary {
      /* margin: auto 0 10px !important; */
    }
  }
}

.email-sent {
  display: block;
  height: 56px;
  width: 66px;
}

.top-background {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  path {
    fill: $pl-brown;
  }
}

.rating-container {
  margin: 12px 0;
  align-items: center;
  display: flex;
  pointer-events: none;

  &.clickable {
    pointer-events: initial;

    .rating-star {
      cursor: pointer;
    }
  }

  .rating {
    font-weight: bold;
    margin-right: 12.5px;
  }

  .rating-star {
    margin: 0 2.5px;
    user-select: none;
    height: 23px;
  }
}

.DayPickerInput {
  display: initial !important;

  .form-control {
    background: $input-background-color;
    cursor: pointer;
  }
}

.DayPickerInput-Overlay {
  bottom: $input-height;

  &.bottom {
    bottom: unset;
  }

  .DayPicker-Caption {
    > div {
      display: inline-block;
    }

    .active-year {
      margin-left: 10px;
      cursor: pointer;
      border: 1px solid $pl-brown;
      border-radius: 6px;
      padding: 0 10px;

      .day-picker-years {
        max-height: 100px;
        overflow: auto;
        position: absolute;
        background: $white;
        width: 65px;
        box-shadow: $card-box-shadow;
        border-radius: 6px;
        z-index: 999;

        > div {
          cursor: pointer;
          padding: 2px 5px;

          &:hover {
            background: rgba($pl-brown, 0.2);
          }
        }
      }
    }
  }
}

.profile-card {
  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-pencil {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  .btn-primary {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    min-width: unset;
    width: 100%;
    max-width: 150px;
    margin-top: 30px;
  }
}

.yes-no-buttons {
  .btn {
    min-width: 0;
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
    margin: 0 7.5px;
  }
}

.toaster {
  width: 265px;

  @media (min-width: $tablet-portrait) {
    width: 360px;
  }
  &:first-of-type {
    margin-top: 90px;
  }

  > div {
    width: 100%;
  }
}

.BHG-search-select {
  .BHG-search-select__control {
    border: none;

    .BHG-search-select__value-container {
      @extend .form-control;
      padding-top: 0;
      padding-bottom: 0;
      transition: $input-transition;
    }
  }

  .BHG-search-select__control--is-focused {
    border: none;
    box-shadow: none;

    .BHG-search-select__value-container {
      box-shadow: $card-box-shadow;
    }

    .BHG-search-select__placeholder {
      position: initial;
      line-height: $input-line-height;
      transform: none;
    }
  }

  /*.BHG-search-select__placeholder {
    position: initial;
    line-height: $input-line-height;
    transform: none;
  }*/

  .BHG-search-select__menu {
    top: $input-height + 5px;
    margin: 0;
    border-radius: $input-border-radius;
    border: none;
    box-shadow: $card-box-shadow;
    z-index: 999;

    .BHG-search-select__option {
      padding: 4px 12px;
      font-size: 14px;
      line-height: 17px;

      &:focus {
        outline: none;
      }

      &.BHG-search-select__option--is-focused {
        background: $brand-primary;
        color: $white;
      }
    }
  }

  .BHG-search-select__indicators {
    display: none;
  }
}

.services-selection {
  .pill-group {
    margin-bottom: 20px;

    p {
      margin-bottom: 10px;
    }

    .BHG-pill-container {
      margin-top: 10px;
    }
  }
}

.filters-card {
  transition: all 0.5s;

  &.open {
    .filter-options {
      opacity: 1;
    }
  }

  .title {
    font-weight: bold;
    display: block;
  }

  .active-filters {
    display: flex;
    overflow: auto;

    @media (min-width: $tablet-portrait) {
      overflow: initial;
    }

    .toggle {
      margin-right: 25px;
    }

    &.is-active {
      //margin: 20px 0 27.5px;
    }
  }

  .filter-options {
    transition: all 0.5s;
    margin-left: -45px;

    @media (min-width: $tablet-portrait) {
      height: 150px;
    }

    .top {
      padding: 0 0 15px 20px;
      display: flex;
      width: 133%;

      @media (min-width: $tablet-portrait) {
        width: 100%;
      }

      .title {
        margin-right: 30px;
      }

      .top-link {
        text-align: center;
        cursor: pointer;
        user-select: none;
        margin: 0 20px;

        @media (min-width: $tablet-portrait) {
          width: 180px;
          margin: 0;
        }

        &.active {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    .bottom {
      width: 130%;

      @media (min-width: $tablet-portrait) {
        width: 100%;
      }
    }
  }
}

.filters-toggle {
  width: 20px;
  height: 19px;
  cursor: pointer;
}

.partners-list {
  padding: 15px 0;

  .intro {
    padding: 20px;
  }

  .list {
    overflow-y: auto;

    .partner-item {
      padding: 30px 40px;
      transition: all 0.5s;
      cursor: pointer;
      border-bottom: 1px solid $lighter-grey;

      &:hover,
      &.active {
        background: $input-background-color;
        border-bottom-color: transparent;
      }

      .partner-name {
        display: block;
        font-weight: bold;
      }

      .partner-website {
        font-size: 14px;
      }

      .bottom-details {
        display: flex;
        align-items: center;
        margin-top: 15px;

        > div {
          display: flex;
          align-items: center;
          margin-right: 20px;

          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .rating-container {
    margin: 8px 0;

    img {
      height:15px;
    }
  }
}

.multi-file-uploader {
  background: $input-background-color;
  padding: 20px 0 0 20px;
  margin: 10px 0;
  border-radius: $card-border-radius / 2;
  display: flex;
  flex-wrap: wrap;

  input {
    display: none;
  }

  .file {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 20px 20px 0;
    background: $light-grey;
    padding: 20px;
    border-radius: 5px;
    text-align: center;

    &::first-line {
      margin-top: 10px;
    }

    label {
      display: block;
      margin: auto 0 0;
    }

    .file-icon {
      display: block;
      max-width: 20px;
      margin: 0 auto 10px;
    }
  }

  .add-new {
    background: $light-grey;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s;
    user-select: none;
    margin: 0 0 20px;

    &:hover {
      background: $lighter-grey;
    }

    .attachment-icon {
      display: block;
      margin: 0 auto 10px;
    }

    span {
      margin: 0;
      cursor: pointer;
    }
  }
}

.rfps-list {
  padding: 15px 0;

  .intro {
    margin-bottom: 35px;

    h3 {
      margin-bottom: 25px;
    }
  }

  .list {
    overflow-y: auto;

    .rfp-item {
      padding: 20px 30px;
      transition: all 0.5s;

      cursor: pointer;
      border-bottom: 1px solid $lighter-grey;

      &:hover,
      &.active {
        background: $input-background-color;
        border-bottom-color: transparent;
      }

      .rfp-name {
        display: block;
        font-weight: bold;
      }

      .rfp-website {
        font-size: 14px;
      }

      .partner-name {
        display: block;
        font-weight: bold;
      }
      .partner-website {
        font-size: 14px;
      }
      .bottom-details {
        display: flex;
        align-items: center;


        > div {
          display: flex;
          align-items: center;
          margin-right: 20px;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.details:not(.auth) {
  margin: 10px 0 10px;
}

.pl-back {
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
  cursor: pointer;
  margin-bottom: 20px;
}

.profile-dropdown {
  position: relative;

  &.open {
    .menu {
      max-height: 230px;
      max-width: 195px;
      border-radius: 0;
    }
  }

  .link {
    cursor: pointer;

    img {
      height: 50px;
      width: 50px;
      max-width: 50px;
      max-height: 50px;
      border-radius: 50%;
      border: 2px solid $light-grey;
    }
  }

  .menu {
    max-height: 0;
    max-width: 0;
    border-radius: 50% 0 50% 50%;
    width: 200px;
    position: absolute;
    top: 100%;
    right: 0;
    overflow: hidden;
    background: $white;
    box-shadow: $card-box-shadow;
    transition: all 0.5s ease-in-out, border-radius 0.8s;

    nav {
      padding: 15px;
      width: 200px;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          &.separator {
            border-bottom: $pl-light-border;
            margin: 10px 0;
          }

          a {
            color: $black;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.verify-boxes {
  display: flex;
  justify-content: center;
  margin: 35px 0;

  input {
    max-width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0 4px 0 0;
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }

    &:nth-child(3) {
      margin-right: 30px;
    }
  }
}

.pl-menu {
  padding: 30px 0;
  border-top: $pl-light-border;
  border-bottom: $pl-light-border;

  nav {
    @media (min-width: $tablet-portrait) {
      display: flex;
      justify-content: center;
    }

    a {
      margin: 5px 20px;
      font-weight: 400;
      display: block;

      @media (min-width: $tablet-portrait) {
        margin: 0 60px;
      }

      &.active {
        font-weight: 700;
      }
    }
  }
}

.testimonial-details {
  cursor: pointer;
  padding: 10px 0;
  border-bottom: $pl-light-border;

  @media(min-width: $tablet-portrait) {
    padding: 25px 0;
  }

  .rating-container {
    .rating-star {
      height: 11px;
      margin: 0 1px 0 0;
    }
  }

  div {

    p {
      margin-bottom: 0!important;
    }

  }
}

.attachments {
  .attachment {
    display: flex;
    align-items: center;
    user-select: none;

    .attachment-icon {
      margin-right: 10px;
    }
  }
}

.award-details {
  padding: 25px 0;
  border-top: $pl-light-border;

  .details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
}

.invite-team-form {
  label {
    margin: 0 0 10px;
  }

  .input-row {
    margin-bottom: 10px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 0;
    }
  }

  .btn {
    min-width: 0;
    margin: 0 0 20px;
    height: $input-height;
    line-height: $input-line-height;

    @media (min-width: $tablet-portrait) {
      margin: 0;
    }
  }
}

.users-list {
  .col-md-1 {
    display: flex;
    align-items: center;

    .user-status {
      height: 25px;
      min-width: 25px;
      width: 25px;
      border-radius: 50%;

      &.invited {
        background: #ffbf00;
      }

      &.active {
        background: lighten($pl-green, 20%);
      }
    }
  }
}

.account-details-card {
  @media (min-width: $tablet-portrait) {
    margin: 20px 0 20px;
  }

  .row {
    @media (min-width: $tablet-portrait) {
      margin: 20px -15px 20px;
    }

    > div {
      margin: 10px 0;

      @media (min-width: $tablet-portrait) {
        margin: 20px 0 30px;
      }

      .detail-title {
        font-weight: bold;
      }

      .detail-value {
        margin-left: -15px;
        @media (min-width: $tablet-portrait) {
          margin: 0;
        }
      }
    }
  }
}

.search-box {
  position: absolute;
  right: 0;
  z-index: 999;

  &.open {
    .main .input-container {
      width: 250px;
    }
  }

  .main {
    display: flex;
    align-items: center;

    .input-container {
      width: 0;
      overflow: hidden;
      transition: width 0.5s;

      input {
        background: $input-background-color;
        height: 30px;
        border: none;
        padding: 0 15px;
        width: 100%;
      }
    }

    .search-toggle {
      padding: 0 10px;
      height: 30px;
      cursor: pointer;
      background: $input-background-color;
    }
  }

  .search-handler {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: all 0.5s;
    width: 125%;
    margin-left: -25%;

    &.open {
      height: 60px;
      overflow: visible;

      .inner {
        height: 45px;
      }
    }

    .inner {
      position: absolute;
      background: $white;
      right: 0;
      padding: 10px;
      box-shadow: $card-box-shadow;
      height: 0;
      width: 100%;
      overflow: hidden;
      transition: height 0.5s;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        word-break: break-word;
        flex: 9;
      }

      .btn {
        min-width: 0;
        padding: 0;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
      }
    }
  }
}

.full-testimonial-details {
  .top {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    .company-name {
      background: $pl-blue;
      color: $white;
      padding: 2.5px 10px;
      border-radius: 6px;
    }
  }

  .details {
    margin-bottom: 10px;

    .row {
      > div {
        align-items: center;
        display: flex;
      }
    }

    .col-md-7,
    .col-md-8 {
      margin-bottom: 10px;
    }

    .col-md-5,
    .col-md-4 {
      text-align: right;

      .rating-container {
        justify-content: flex-end;
        margin: 0 0 0 auto;
      }

      .value {
        margin-left: auto;
      }
    }

    .title {
      font-weight: bold;
    }

    .rating-star {
      max-width: 11px;
      margin: 0 1px;
    }
  }
}


.satisfaction-scale {
  display: flex;
  align-items: center;

  > div {
    border-radius: 25px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    margin: 0 5px;
    line-height: 30px;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }

    &:hover,
    &.active {
      font-weight: bold;
      box-shadow: $card-box-shadow;
    }
  }
}

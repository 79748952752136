//HEADINGS
h1,h2,h3,h4,h5,h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
}

h1 {
  font-size: 36px;
  line-height: 45px;
}

//PARAGRAPHS
p {
  margin: $paragraph-margin;
  font-family: $paragraph-font-family;
  font-weight: $paragraph-font-weight;
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  color: $paragraph-color;
}

//BYTEHOGS
.BHG-pill-container {
  display: flex;
  flex-wrap: wrap;

  .BHG-pill {
    margin: 0 10px 12.5px 0;
    height: 25px;
    line-height: 25px;
    border-radius: 19px;
    padding: 0 15px;
    cursor: pointer;
    user-select: none;
    position: relative;

    .remove-icon {
      position: absolute;
      right: -3px;
      top: -6px;
      width: 15px;
    }
  }
}

.BHG-toggle {
  height: 20px;
  width: 45px;
  padding: 2px;
  border-radius: 20px;
  background: lightgray;
  cursor: pointer;
  transition: all .5s;
  margin-left: 15px;

  &.active {
    background: lightgreen;

    .slider {
      margin-left: calc(100% - 16px);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  .slider {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: white;
    transition: all .5s;
    margin-left: 0;
  }
}

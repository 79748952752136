.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.70);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
}

.modal {
  position: absolute;
  background: $white;
  overflow: auto;
  border-radius: 6px;
  outline: none;
  padding: 20px;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.animated {
    transition: all .5s;
  }

  &.logo {
    max-width: 250px;
    max-height: 150px;
    padding: 0;
    overflow: hidden;
    transition: max-height .3s;

    &.image {
      max-height: 375px;
    }

    .choose-file {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .pp-editor {
      canvas {
        margin: 0 auto;
        display: block;
      }

      .bottom {
        margin-top: 10px;

        .form-error {
          text-align: center;
          width: 100%;
          display: block;
          margin-bottom: -30px;
        }

        .buttons {
          margin: 10px 20px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .btn {
            min-width: unset;
          }
        }
      }
    }
  }

  &.testimonial-requested, &.password-reset {
    max-width: 700px;
    max-height: 430px;
    padding: 60px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .testimonial-modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h1 {
      margin: 0;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      max-width: 450px;
      text-align: center;
      margin: 45px 0;
    }

    .email-sent {
      margin-bottom: 20px;
    }
  }

  &.update-user {
    max-width: 600px;
    max-height: 450px;
    padding: 60px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0 0 20px;
    }

    form {
     width: 50%;
    }

    .yes-no-buttons {
      margin-left: -7.5px;
      margin-top: 5px;
    }

    .form-error {
      margin: 10px 0 -10px;
    }

    .btn-primary {
      margin: 40px auto 0;
      display: block;
    }
  }


  &.super-admin {
    max-width: 600px;
    max-height: 500px;
    padding: 60px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;

    h1 {
      margin: 0 0 20px;
    }

    > div {
      width: 75%;
    }

    .form-error {
      margin: 10px 0 -10px;
    }

    .BHG-search-select__value-container {
      margin:0;
    }

    .BHG-search-select__menu-list {
      max-height: 150px;
    }

    .btn-primary {
      margin: 20px auto;
      display: block;
    }
  }

  &.add-award {
    max-width: 575px;
    max-height: 480px;
    padding: 60px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0 0 20px;
    }

    .form-error {
      margin: 10px 0 -10px;
    }

    .btn-primary {
      margin-top: 20px;
    }

    .DayPickerInput-Overlay {
      bottom: unset;
      top: -45px;
      left: -30px;
    }
  }

  &.request-testimonial {
    max-width: 500px;
    max-height: 700px;
    padding: 60px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.testimonial-details {
    max-width: 500px;
    max-height: 90%;
    padding: 20px 30px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(min-width: $tablet-portrait) {
      max-height: 560px;
    }

    .btn {
      margin: 20px 0 0;
    }
  }


  &.update-details {
    max-width: 600px;
    max-height: 580px;
    padding: 40px 0 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0 0 10px;
    }

    .tab-menu {
      padding: 0 0 10px;

      .tab-item {
        font-size: 18px;
        cursor: pointer;
        user-select: none;
        margin: 0 10px;

        &.active {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .tab-content {
     width: 60%;
      overflow: auto;

      .services-selection, form {
        padding-bottom: 20px;
      }
    }

    .form-error {
      margin: 10px 0 -10px;
    }

    .btn-primary {
      margin: 20px auto 0;
      display: block;
    }
  }

  &.contact-partner {
    max-width: 550px;
    max-height: 600px;
    padding: 60px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0 0 20px;
    }

    form {
      width: 75%;
    }

    .form-error {
      margin: 10px 0 -10px;
    }

    .btn-primary {
      margin: 20px auto 0;
      display: block;
    }
  }

  &.testimonial-success {
    max-width: 500px;
    max-height: 250px;
    padding: 20px 30px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-portrait) {
      //max-height: 560px;
    }

    > div {
      text-align: center;
    }

    .btn {
      display: block;
      margin: 20px auto;
      max-width: 220px;
    }
  }
}

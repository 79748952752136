.view.set-testimonial {
  .container {
    position: relative;
    padding-top: 135px;

    .form-container {
      margin-top: 45px;
    }

    .ratings-row {
      margin-bottom: 30px;

      > div {
        justify-content: space-between;
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 7.5px;
        }
      }
    }

    .project-completion-qs {
      margin-top: 25px;
      margin-bottom: 10px;

      .top {
        display: flex;
        align-items: center;
      }

      .content {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;

        .internal {
          padding: 5px 0;
        }

        &.active {
          max-height: 100px;
        }
      }
    }

    .recommend-container {
      display: flex;
      align-items: center;
      margin-top: 25px;
    }

    .submit-btn {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }
}

.view.create-rfp {
  .container {
    padding-top: 80px;
    @media (min-width: $tablet-portrait) {
      position: relative;
      padding-top: 135px;
      padding-bottom: 50px;
    }

    .form-container {
      margin-top: 45px;
    }

    label:not(.add-new) {
      margin: 15px 0;
    }

    .override {
      margin-top: 57px;
    }

    h1 {
      margin-bottom: 20px;
    }

    .BHG-pill-container {
      margin-top: 10px;
    }

    .recommend-container {
      display: flex;
      align-items: center;
      margin-right: 25px;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      max-width: 550px;
      margin: 45px auto;
    }

    .submit-btn {
      margin-top: 20px;
    }
  }
}

.view.manage-rfps {
  .main-content {
    position: relative;
    padding-top: 80px;
    height: 100%;

    @media (min-width: $tablet-portrait) {
      padding-top: 100px;
    }

    .pl-back {
      display: none;
    }

    .container {
      @media (max-width: ($tablet-portrait - 1px)) {
        .no-gutters {
          margin: 0 -15px;
        }

        &.active {
          .create-intro {
            height: 0;
          }

          .col-md-3 {
            margin-left: -100%;
          }

          .pl-back {
            display: flex !important;
            margin: 10px 0;
          }
        }
      }
    }

    .intro {
      h3 {
        //margin-top: 10px;
      }
    }

    .create-intro {
      padding: 10px 0;
      overflow: hidden;

      @media (min-width: $tablet-portrait) {
        padding: 10px 0;
      }

      h3 {
        //margin-top: 10px;
      }

      p {
        margin: 10px 0;
      }
    }

    .partner-services-card {
      @extend .pl-card;
      padding: 35px 35px 25px;
      border-radius: $card-border-radius / 2;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

      .detail-title {
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        color: $black;
        margin-bottom: 20px;
      }

      .detail-value {
        font-size: 14px;
        line-height: 17px;
        font-weight: normal;
        color: $black;
      }

      .no-gutters > div {
        margin-bottom: 10px;
      }

      .sect-2 .detail-value {
        margin-left: 20px;
      }
    }

    .col {
      h3 {
        margin: 25px 0;
      }
    }
  }
}

.active-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  span {
    width: 50px;
  }
}

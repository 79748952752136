.view.team {
  .main-content {
    position: relative;
    padding-top: 111px;
    height: 100%;

    h3 {
      margin: 25px 0;
    }

    .users-list {
      label {
        font-size: 16px;
      }

      span {
        display:block;
        margin: 5px 0;

        @media(min-width: $tablet-portrait) {
          margin: 15px 0;
        }
      }
    }
  }
}
